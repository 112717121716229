import React from "react";
import EventbriteButton from "react-eventbrite-popup-checkout";
import './navigation.css';

class EventbriteNav extends React.Component {
    constructor() {
      super();
      this.state = {
        orderConfirm: false
      };
    }
  
    testSubmit = () => {
      setTimeout(this.skipData, 1000);
    };
  
    skipData = () => {
      window.location.href =
        "https://casperkarmayogafestival.com";
    };
  
    testData = () => {};
  
    render() {
      return (
        <div id="nav">
          <EventbriteButton
            ebScriptPath="https://www.eventbrite.com/static/widgets/eb_widgets.js"
            ebEventId="615657296547"
            className="nav-link"
            onOrderComplete={() => this.testSubmit()}
            onClick={() => this.testData()}
          >
            Tickets
          </EventbriteButton>
        </div>
      );
    }
  }


  
export {EventbriteNav};

