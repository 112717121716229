import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import {
  // Home,
  // About,
  // Classes,
  // ExploreTheFest,
  // GiveBack,
  // Instructors,
  // Plan,
  // Vendors,
  Landing
}
  from './Screens/sreens-index';
//import { Navigation, Footer, SubscribeForm } from './Components/components-index';
import ScrollToTop from "./Components/scroll-to-top";

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        {/* <Navigation /> */}
        <Routes>
          <Route index element={<Landing/>}/>
          {/* <Route index element={<Home />} /> 
          <Route element={<About />} path="about" />
          <Route element={<Classes />} path="classes" />
          <Route element={<ExploreTheFest />} path="explore-the-fest" />
          <Route element={<GiveBack />} path="give-back" />
          <Route element={<Instructors />} path="instructors" />
          <Route element={<Plan />} path="plan" />
          <Route element={<Vendors />} path="vendors" /> */}
        </Routes>
        {/* <SubscribeForm />
        <Footer /> */}
      </div>
    </Router>
  );
}

export default App;
