import React from "react";
import { Row, Col, Container } from 'react-bootstrap';

import WyomingYoga from '../../assets/sponsors/2024/WyomingYogaAndWellness.webp';
import DoyleJohnson from '../../assets/sponsors/2024/DoleJohnson.webp';
import HealingWaters from '../../assets/sponsors/2024/307HealingWaters.webp';
import MayaFire from '../../assets/sponsors/2024/MayaFire.webp';
import TheBreathingRoom from '../../assets/sponsors/2024/BreathingRoom.webp';
import PranicQuest from '../../assets/sponsors/2024/APranicQuest.webp';
import TheBodyBar from '../../assets/sponsors/2024/BodyBar.webp';
import Zest from '../../assets/sponsors/2024/zest.webp';
import InnerBlissYoga from '../../assets/sponsors/2024/InnerBlissYoga.webp';
import Whites from '../../assets/sponsors/2024/WhitesMountainMoters.webp';
import CompleteYogaMassage from '../../assets/sponsors/2024/CompleteYogaAndMassage.webp';
import WildFlowerYoga from '../../assets/sponsors/2024/WildflowerYogaStudio.webp';
import TheBodyWitch from '../../assets/sponsors/2024/TheBodyWitch.webp';
import JillRealEstate from '../../assets/sponsors/2024/JillKamberKnoxRealEstate.webp';
import Lifetime from '../../assets/sponsors/2024/Lifetime.webp';
import kr from '../../assets/sponsors/2024/kr.webp';
import AtmanWellness from '../../assets/sponsors/2023/ATMAN.webp';
import Exero from '../../assets/sponsors/2024/ExeroX.webp';
import TheMysticSip from '../../assets/sponsors/2024/TheMysticSip.webp';
import BalancedHealingSaltSauna from '../../assets/sponsors/2023/BalancedHealing.webp';
import InspiredSproutsYoga from '../../assets/sponsors/2024/InspiredSproutsYoga.webp';
import acs from '../../assets/sponsors/2024/acs.webp';

import "../../theme/index.css";

export const SponsorList = () => {
    return (
        <section className="px-0 mx-0">
            <Container>
                <Row className="pt-5 g-0">
                    <Col className="text-center px-0">
                        <h2>Thank you to our 2024 Sponsors!</h2>
                        <p>A heartfelt thank you to our incredible sponsors for their generous support and
                             contributions. Their commitment and dedication make this event possible, allowing
                              us to bring together a vibrant community for a celebration of wellness and 
                              creativity. Without their invaluable support, this festival simply wouldn’t
                              be possible.</p>
                    </Col>
                </Row>
                <Row className="justify-content-center align-items-center g-0 p-5">
                    <Col md={2} className="px-3 pt-2">
                        <a href="http://wyomingyoga.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={WyomingYoga} alt="Wyoming Yoga and Massage Logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.doylejohnsonsautorepair.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={DoyleJohnson} alt="Doyle Johnson logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://307healingwaters.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={HealingWaters} alt="307 Healing Waters logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://307healingwaters.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={MayaFire} alt="Maya Fire logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-2 pt-2">
                        <a href="https://www.breathingroom2470.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={TheBreathingRoom} alt="The Breathing Room logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://thebodybar307.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={TheBodyBar} alt="The Body Bar Logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.facebook.com/HannahLillianPranicQuest" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={PranicQuest} alt="A Pranic Quest logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.wyozest.net/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={Zest} alt="Zest Logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.innerbliss-collective.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={InnerBlissYoga} alt="Inner Bliss Yoga logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.whitesmountainchevy.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={Whites} alt="White's Mountain Moters logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-2 align-self-center pt-1">
                        <a href="https://www.completeyogaandmassage.com/" rel="noreferrer" target="_blank" >
                            <img className="img-fluid" src={CompleteYogaMassage} alt="Complete Yoga and Massage/ Kim Nimmo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://wyowildflower.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={WildFlowerYoga} alt="Wild Flower Yoga" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://linktr.ee/thebodywitch?fbclid=IwAR2je-ldluDhUbJCaxb71g84pxLDhF3jqdm-spKtT0kYSaBqc8Ttk0gVCeY" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={TheBodyWitch} alt="The Body Witch logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://jillkamberknox.sites.cbmoxi.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={JillRealEstate} alt="Jill Kamber-Knox Real Estate logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://lifetime307.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={Lifetime} alt="Lifetime 307 logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://kaylaromerophotography3.mypixieset.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={kr} alt="Kayla Romero Photography logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.atmanwellnessllc.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={AtmanWellness} alt="Atman Wellness logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.exerousa.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={Exero} alt="Exero logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.facebook.com/p/The-Mystic-Sip-61558404750195/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={TheMysticSip} alt="The Mystic Sip logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.balancedhealingsaltandsauna.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={BalancedHealingSaltSauna} alt="Balanced Healing Salt + Sauna logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-2 pt-2">
                        <a href="https://www.facebook.com/InspiredSprouts/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={InspiredSproutsYoga} alt="Inspired Sprouts Yoga logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.facebook.com/AtlasCabinetShop/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={acs} alt="Inspired Sprouts Yoga logo" />
                        </a>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}