import React from "react";
import {Row, Col } from "react-bootstrap";

import '../../Components/landing.css';
import '../../theme/index.css';

import logo from '../../assets/2024/landing/CASPER KARMA YOGA FEST_LOGO_ONE COLOR@0.75x.png';
import saveTheDate from '../../assets/2024/landing/SaveTheDate.webp';
import july20 from '../../assets/2024/landing/July20.webp';
import flowersLeft from '../../assets/2024/landing/flowersLeft.webp';

export const Header = () => {
    return (
        <div id="landing" className="pb-0 landing-bkg-container">
            <Row className="justify-content-between py-0 mx-0">
                <Col md="auto" className="p-0">
                    <img src={logo} alt="" className="landing-logo pt-1 ps-3" />
                </Col>
                <Col col={3} className="p-0">
                    <img src={flowersLeft} alt="" className="flower-image flip180" />
                </Col>
            </Row>
            <Row className="justify-content-center mt-4 p-0 mx-0">
                <Col lg={8} xs={12}>
                    <img src={saveTheDate} className="save-the-date" alt="Save the Date" />
                </Col>
            </Row>
            <Row className="justify-content-center align-items-center p-4 mx-0">
                <Col md={4} sm={6} xs={8} className="text-center p-2">
                    <img src={july20} className="img-fluid dropshadow" alt="July 20, 2024" />
                </Col>
                <Col md={{ span: 3, offset: 1 }} sm={{ span: 4, offset: 1 }} xs={8} className="justify-content-center text-center p-2">
                    <h3 className="white-text p-1 dropshadow">
                        Yoga, Music, Kids Corner, Food Trucks & More
                    </h3>
                </Col>
            </Row>
            <Row className="text-center pb-5 mx-0">
                <Col>
                    <h1 className="white-text">Washington Park</h1>
                    <h2 className="white-text">Casper, WY</h2>
                </Col>
            </Row>
        </div>
    )
};