import React from "react";
import { Row, Col } from "react-bootstrap";

import '../../Components/landing.css';
import '../../theme/index.css';

export const Tiers = () => {
    return (
            <div>
                <Row id="tiers" className="px-5 pt-5 justify-content-center mx-0">
                    <Col md={12} className="pb-4"><h3 className="text-center  bluegray-text">Sponsorship Tiers</h3></Col>
                    <Col md={4}>
                        <h4><strong>Tier 1 - $50</strong></h4>
                        <ul>
                            <li>1 Social Media Mention</li>
                            <li>1 Raffle Ticket</li>
                        </ul>
                    </Col>
                    <Col md={4}>
                        <h4><strong>Tier 2 - $150</strong></h4>
                        <ul>
                            <li>4 Social Media Mention</li>
                            <li>1 Email Feature</li>
                            <li>1 Festival T-Shirt</li>
                            <li>2 Raffle Ticket</li>
                        </ul>
                    </Col>
                    <Col md={4}>
                        <h4><strong>Tier 3 - $250</strong></h4>
                        <ul>
                            <li>Everything from previous level</li>
                            <li>2 Festival T-Shirt</li>
                            <li>3 Raffle Ticket</li>
                        </ul>
                    </Col>
                    <Col md={4}>
                        <h4><strong>Tier 4 - $500</strong></h4>
                        <ul>
                            <li>Everything from previous level</li>
                            <li>Live Business Mention</li>
                            <li>Logo on Festival T-Shirt</li>
                            <li>Banner Space</li>
                            <li>4 Raffle Tickets</li>
                        </ul>
                    </Col>
                    <Col md={4}>
                        <h4><strong>Tier 5 - $1250</strong></h4>
                        <ul>
                            <li>Everything from previous level</li>
                            <li>2 Email Features</li>
                            <li>4 Event T-Shirts</li>
                            <li>Priority Banner Space</li>
                            <li>5 Raffle Tickets</li>
                        </ul>
                    </Col>
                    <Col md={4}>
                        <h4><strong>Tier 6 - $5000</strong></h4>
                        <ul>
                            <li>Everything from previous level</li>
                            <li>Priority Logo Placement on Festival T-Shirt</li>
                            <li>6 Raffle Tickets</li>
                        </ul>
                    </Col>
                    <Col md={4}>
                        <h4><strong>Location Sponsor</strong></h4>
                        <ul>
                            <li>Social Media & Live Mentions</li>
                            <li>Logo on Website</li>
                            <li>Banner Space</li>
                        </ul>
                    </Col>
                    <Col md={4}>
                        <h4><strong>Drink Sponsor</strong></h4>
                        <ul>
                            <li>Social Media & Live Mentions</li>
                            <li>Logo on Website</li>
                            <li>Banner Space</li>
                        </ul>
                    </Col>
                    <Col md={4}>
                        <h4><strong>Volunteer Sponsor</strong></h4>
                        <ul>
                            <li>Social Media & Live Mentions</li>
                            <li>Logo on Volunteer Shirt</li>
                        </ul>
                    </Col>
                </Row>
            </div>
    )
};