import React from "react";
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import "../../theme/index.css";

import projectKenny from '../../assets/2024/projectKenny.webp'

export const Benefactor = () => {
    return (

            <Row className="my-auto bluegray-bkg g-0">
                <Col lg={{span: 6, order: "first"}} md={{span: 6, order:"first"}} sm={{ span: 12, order: "first"}} className="my-auto p-5 text-center">
                    <h2 className="white-text">Pay it Forward, One Downward Dog at a Time</h2>
                    <p className="white-text py-3">Sponsor the event, be a vendor, donate, or simply attend the event, every bit helps.
                            This year Project Kenny is the Casper Karma Yoga Festival beneficiary. We are excited 
                            to support them in connecting service dogs to veterans in need.</p>
                    <a href="https://www.projectkenny.org/" target="_blank" rel="noreferrer">
                   <button>Learn More</button>
                   </a>
                </Col>
                <Col md={6} className="px-0">
                    <img src={projectKenny} className="rectangle-image" alt="two people with one hand on heart and the other touching the other person's hand" />
                </Col>
            </Row>
    )
}