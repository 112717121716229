import React from "react";
import { Row, Col } from "react-bootstrap";

import '../../Components/landing.css';
import '../../theme/index.css';

export const Applications = () => {
    return (
            <div id="applications">
                <Row className="justify-content-center text-center p-3 pt-4 mx-0">
                    <Col md={8}>
                        <h2 className="bluegray-text">Applications are open for Vendors, Sponsors & Instructors!</h2>
                    </Col>
                </Row>
                <Row className="px-2 justify-content-center mx-0 bluegray-text">
                    <Col md="auto" className="p-2">
                        <h3>SPONSORS</h3>
                        <h4>• 6 Tiers Available</h4>
                        <h4>• Starting at $50</h4>
                        <h4>• Social Media Mentions</h4>
                        <h4>• Raffle Tickets And More</h4>
                    </Col>

                    <Col md={{span: "auto", offset: 1}} className="p-2">
                        <h3>VENDORS</h3>
                        <h4>• $75/Booth</h4>
                        <h4>• 10X10 Vendor Booth</h4>
                        <h4>• Social Media Mentions</h4>
                        <h4>• Link and Logo on Website</h4>
                    </Col>
                </Row>
                <Row className="justify-content-center text-center mx-0">
                    <Col md={2} xs={11} className="pt-3">
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSc7cFOzeB9qydq4rSUvxeXAa3PQvF7VP9jTvOOgB6aPcsOJOA/viewform" target="_blank" rel="noreferrer">
                            <button>
                                APPLY
                            </button>
                        </a>
                    </Col>
                </Row>
                <Row className="justify-content-center mx-0 pb-5  bluegray-text">
                    <Col md={8} className="text-center pt-5 px-3">
                        <h3>INSTRUCTORS</h3>
                        <h4>Looking for both meditation and unique yoga offerings</h4>
                        <Row className="justify-content-center mx-0">
                            <Col md={3} xs={12} className="pt-3">
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSeLRDIo6l1CiIJQyVu_LpxHo6U9HX1OzxxY1PVGua4TRbLyKQ/viewform" target="_blank" rel="noreferrer">
                                    <button>APPLY</button>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                </div>
    )
};