import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagram } from "@fortawesome/free-brands-svg-icons";

import '../Components/landing.css';
import '../theme/index.css';
import { Header, Tickets, Applications, Tiers, Benefactor, SponsorList, VendorList2024 } from '../Components/components-index';

import prayerFlag from '../assets/2024/landing/prayer-flags-bkg.webp';

export const Landing = () => {
    return (
        <Container fluid className="px-0">
            <Header/>
            <Tickets/>
            <Benefactor/>
            <div id="rainbowBkg" className="pt-4">
                <SponsorList/>
                <VendorList2024/>
                <div className="prayer-flags-bkg mx-0">
                    <Row className="justify-content-center white-text text-center mx-0">
                        <Col md={12} className="px-0">
                            <img src={prayerFlag} alt="prayer flags" className="img-fluid" />
                        </Col>
                        <Col className="p-2 tagline-text">
                            <h3 className="white-text dropshadow">Planting the seeds of positive karma in our community.</h3>
                        </Col>
                    </Row>
                </div>
                </div>
                <div id="rainbowBkg" className="pt-4">
                <Applications/>
                <Tiers/>

                <Row className="justify-content-center text-center mx-0">
                    <Col md={8}>
                        <div className="pt-4">
                            <a className="social-links" target="_blank" rel="noreferrer" href="https://www.facebook.com/casperkarmayogafest" ><FontAwesomeIcon className="left social-icon" icon={faFacebookSquare} /></a>
                            <a className="social-links" target="_blank" rel="noreferrer" href="https://www.instagram.com/casperkarmayogafest/"><FontAwesomeIcon className="left social-icon " icon={faInstagram} /></a>
                            <br />
                            <a href="mailto:casperkarmayogafest.gmail.com" className="blue-text">CasperKarmaYogaFest@gmail.com</a>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center pt-3 mx-0">
                    <Col md={12} className="text-center">
                        <p><small>Copyright © 2024 Casper Karma Yoga Festival, all rights reserved.</small></p>
                    </Col>
                </Row>
            </div>
        </Container>
    )
};