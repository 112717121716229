import React from "react";
import { Row, Col, Container } from 'react-bootstrap';

import WyomingYoga from '../../assets/sponsors/2024/WyomingYogaAndWellness.webp';
import HealingWaters from '../../assets/sponsors/2024/307HealingWaters.webp';
import MayaFire from '../../assets/sponsors/2024/MayaFire.webp';
import SantoshaYoga from '../../assets/sponsors/2024/SantoshaYoga.webp';
import SacredScounds from '../../assets/sponsors/2024/SacredSounds.webp';
import SacredTreeMassage from '../../assets/sponsors/2024/SacredTreeWyoming.webp';
import TheBodyBar from '../../assets/sponsors/2024/BodyBar.webp';
import LuthersLight from '../../assets/sponsors/2024/LuthersLight.webp';
import RadicalLotus from '../../assets/sponsors/2024/radicalLotus.webp';
import MosaicMassageSchool from '../../assets/sponsors/2024/MosaicMassage.webp';
import LightTouchFootZone from '../../assets/sponsors/2024/LightTouchFootZone.webp'
import HeirloomNative from '../../assets/sponsors/2024/Heirloom&Native.webp';
import TrueBakery from '../../assets/sponsors/2024/TrueBakery.webp';
import CurlyWolfDen from '../../assets/sponsors/2024/CurlyWolfDen.webp';
import Turtle from '../../assets/sponsors/2024/turtle.webp';
import Six26 from '../../assets/sponsors/2024/Six26.webp';
import MaurieAudubonSociety from '../../assets/sponsors/2024/MaurieAudubonSociety.webp';
import CasperCollege from '../../assets/sponsors/2024/CasperCollege.webp';
import Joshua from '../../assets/sponsors/2024/WyomingBhaktiWithJoshua.webp';
import Arbonne from '../../assets/sponsors/2024/a6.webp';




import "../../theme/index.css";

export const VendorList2024 = () => {
    return (
        <section className="px-0 mx-0">
            <Container>
                <Row className="pt-5 g-0">
                    <Col className="text-center px-0">
                        <h2>Vendors</h2>
                        <p>Discover a vibrant array of vendors offering everything from
                            rejuvenating massage and foot zoning to serene sound and 
                            light baths. Explore unique arts, yoga essentials, clothing,
                            and exquisite jewelry, all designed to elevate your wellness
                            journey. Indulge in delicious food and treats from our select
                            vendors. Get ready to immerse yourself in a world of healing 
                            and creativity!</p>
                    </Col>
                </Row>
                <Row className="justify-content-center align-items-center g-0 p-5">
                    <Col md={2} className="px-3 pt-2">
                        <a href="http://wyomingyoga.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={WyomingYoga} alt="Wyoming Yoga and Massage Logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://307healingwaters.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={HealingWaters} alt="307 Healing Waters logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://307healingwaters.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={MayaFire} alt="Maya Fire logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.santoshayoga307.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={SantoshaYoga} alt="Sanitosha Yoga logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.sacredsoundsvst.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={SacredScounds} alt="Sacred Sounds logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.sacredtreewyoming.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={SacredTreeMassage} alt="Sacred Tree Massage logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://thebodybar307.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={TheBodyBar} alt="The Body Bar Logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.facebook.com/LuthersLight/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={LuthersLight} alt="Luthers Light logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.facebook.com/radicallotus/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={RadicalLotus} alt="Radical Lotus Logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-2 pt-2">
                        <a href="https://mosaicmassagecollege.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={MosaicMassageSchool} alt="Mosaic Massage School logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.facebook.com/lighttouchfootzone/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={LightTouchFootZone} alt="Light Touch Foot Zone logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-2 align-self-center pt-2">
                        <a href="https://eatwyoming.com/heirloomandnative/" rel="noreferrer" target="_blank" >
                            <img className="img-fluid" src={HeirloomNative} alt="Heirloom Native logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.truebakerycasper.com/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={TrueBakery} alt="True Bakery Logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://www.facebook.com/p/The-Curly-Wolf-Den-Fine-Shave-Ice-100057259457841/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={CurlyWolfDen} alt="Curly Wolf Den Shaved Ice logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                            <img className="img-fluid" src={Turtle} alt="Turtle Moon Imports logo" />
                            <h4 className="text-center pt-3">Turtle Moon Imports</h4>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                            <img className="img-fluid" src={Six26} alt="Six26 logo" />
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <a href="https://murieaudubon.org/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={MaurieAudubonSociety} alt="Murie Audubon Society logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-2 pt-2">
                        <a href="https://www.caspercollege.edu/program/biology/" rel="noreferrer" target="_blank">
                            <img className="img-fluid" src={CasperCollege} alt="Casper College Biology logo" />
                        </a>
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                        <img className="img-fluid" src={Joshua} alt="Wyoming Bhakti With Joshua logo" />
                    </Col>
                    <Col md={2} className="px-3 pt-2">
                            <img className="img-fluid" src={Arbonne} alt="Arbonne logo" />
                            <h4 className="text-center">Arbonne by Ceatriss</h4>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}