import React from "react";
import { Row, Col } from 'react-bootstrap';
import '../../theme/index.css';

import YogaForAll from '../../assets/2024/landing/yogaForAll.webp';

export const Tickets = () => {
    return (
        <div>
            <Row className="my-auto g-0 cream-bkg">
                <Col md={6} className="px-0">
                    <img src={YogaForAll} className="rectangle-image" alt="man doing yoga" />
                </Col>
                <Col lg={{span: 6, order: "last"}} md={{span: 6, order:"first"}} sm={{ span: 12, order: "first"}} xs={{ span: 12, order: "first"}} className="my-auto p-5">
                    <h2 className="text-center bluegray-text">Yoga Should Be Inclusive</h2>
                    <p>We’re changing things up with ticketing this year, making it accessible for everyone to enjoy the magic of yoga and its amazing benefits!</p>
                    <h4 className="text-center">🎟️ TICKETS: By Donation Only! 🙌</h4>
                    <p className="pt-2">We believe yoga should be inclusive, so pay what you can afford (or extra for that good karma towards CKYF.)</p>
                    <p>Here are the “suggested” donation prices: <br />
                        <ul>
                            <li>1 Class: $5</li>
                            <li>1/2 Day Pass: $10</li>
                            <li>Full Day Pass: $20</li>
                        </ul>
                    </p>
                    <h4 className="text-center">📝 HOW TO PAY:</h4>
                    <p>
                        <ol>
                            <li>Choose your ticket type.</li>
                            <li>Send your donation via
                                <a href="https://account.venmo.com/u/CasperKarmaYogaFestival" target="_blank" rel="noreferrer"><strong> Venmo</strong></a> to with a note stating your ticket type (e.g., “1 Class,” “1/2 Day Pass,” “Full Day Pass”).</li>
                        </ol>
                    </p>
                    <a href="https://account.venmo.com/u/CasperKarmaYogaFestival" target="_blank" rel="noreferrer" >
                        <button>Buy Tickets</button>
                    </a>
                </Col>
            </Row>
        </div>
    )
}